
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    checkboxLabel: {
      required: true,
    },
    id: {
      required: true,
      type: String,
    },
    model: {
      required: true,
    },
    hasError: {
      required: true,
      type: Boolean,
    },
    error: {
      required: true,
    },
  },
})
export default class FormCheckbox extends Vue {}
