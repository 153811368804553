
import useVuelidate from "@vuelidate/core";
import { email, required } from "@/utils/i18n-validators";
import { Options, Vue } from "vue-class-component";
import SectionTitle from "@/shared/components/SectionTitle.vue";
import InvestmentStrategyCard from "@/shared/components/InvestmentStrategyCard.vue";
import ContractCard from "@/shared/components/ContractCard.vue";
import LeftStepBar from "@/shared/components/LeftStepBar.vue";
import FormInput from "@/shared/components/FormInput.vue";
import { getLocale } from "@/i18n";
import FormCheckbox from "@/shared/components/FormCheckbox.vue";
import { helpers, maxLength, minLength, sameAs } from "@vuelidate/validators";

@Options({
  components: {
    FormCheckbox,
    SectionTitle,
    InvestmentStrategyCard,
    ContractCard,
    LeftStepBar,
    FormInput
  },
  data() {
    return {
      v$: useVuelidate(),
      formDefault: {
        signCode: "",
        signCodeSfa: ""
      },
      checkboxSfa: false,
      checkboxSfa2: false,
      checkboxOcp: false,
      sfaSigned: false,
      ocpSigned: false,
      agentContractsSigned: false,
      ocpContractsSigned: false
    };
  },
  beforeMount() {
    this.$store.commit("onboarding/checkProfile");
  },
  mounted() {
    this.$store.dispatch("onboarding/getContracts");
    this.$store.dispatch("onboarding/getSfaDocuments");
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email
        },
        password: {
          required,
          min: helpers.withMessage(
            this.$t("validations.password_length"),
            minLength(8)
          ),
          max: helpers.withMessage(
            this.$t("validations.password_length"),
            maxLength(20)
          )
        },
        confirmPassword: {
          required,
          sameAsPassword: helpers.withMessage(
            this.$t("validations.password_not_match"),
            sameAs(this.form.password)
          )
        },
        emailCode: { required },
        termsCheckbox: { required, sameAs: sameAs(true) }
      }
    };
  },
  methods: {
    sendSmsCode() {
      this.$store.dispatch("onboarding/getSignCode");
    },
    sendSmsCodeSfa() {
      this.$store.dispatch("onboarding/getSignCodeSfa");
    },
    contractName(contract: any): string {
      return contract[`name_${getLocale()}`];
    },
    filterDocuments(documents: any[], general: boolean): any[] {
      return documents.filter((doc) => doc.general === general);
    },
    async saveSfaContracts() {
      const params = new URLSearchParams();
      params.append("sms_code", this.form.signCodeSfa); // Append the SMS code

      try {
        const response = await this.$store.dispatch("onboarding/verifySignCodeSfa", params);
        // console.log("response", response);
        if (response.status === 200 && response.data.result == "true") {
          this.sfaSigned = true;
          this.agentContractsSigned = true; // Enable the second checkbox
        }
      } catch (error) {
        console.error(error);
      }
    },
    async saveContracts() {
      const params = new URLSearchParams();
      params.append("sms_code", this.form.signCode);

      try {
        const response = await this.$store.dispatch("onboarding/verifySignCode", params); // Dispatch the action
        if (response.status === 200  && response.data.result == "true") {
          this.ocpContractsSigned = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    finalizeContracts() {
      if (this.sfaSigned && this.ocpContractsSigned) {
        this.$store.commit("onboarding/saveContracts");
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters["onboarding/isLoading"];
    },
    form() {
      return this.formDefault;
    },
    validations() {
      return {
        form: {
          signCode: { required },
          signCodeSfa: { required }
        }
      };
    },
    contracts() {
      return this.$store.getters["onboarding/allContracts"];
    },
    sfaDocuments() {
      return this.$store.getters["onboarding/sfaDocuments"];
    }
  }
})
export default class InvestmentStrategy extends Vue {
}
