
import { Options, Vue } from "vue-class-component";
import FormInput from "@/shared/components/FormInput.vue";
import FormSelect from "@/shared/components/FormSelect.vue";
import { required } from "@/utils/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { InvestmentStrategy, SelectedStrategy } from "../types/InvestmentStrategy";
import { helpers, minValue } from "@vuelidate/validators";

@Options({
  components: {
    FormInput,
    FormSelect
  },

  data() {
    return {
      v$: useVuelidate(),
      investmentFrequency: [
        { value: "monthly", name: this.$t("monthly") },
        { value: "quarterly", name: this.$t("quarterly") }
      ],
      feeType: [
        { value: "one-time", name: this.$t("one-time") },
        { value: "regular", name: this.$t("regular") }
      ],
      form: {
        selected: false,
        frequency: "",
        amount: "",
        feeType: "",
        monthlyAmount: "",
        investmentHorizon: "",
        discount: "",
        entryFee: 0,
        entryFeeToSend: 0
      }
    };
  },
  props: {
    strategy: {
      required: true
    },
    cardTitle: {
      type: String
    },
    cardSubtitle: {
      type: String
    },
    hasInvestmentFrequency: {
      default: false,
      type: Boolean
    },
    amountLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    targetAmount() {
      const monthlyAmount = parseFloat(this.form.monthlyAmount.replace(/,/g, "."));
      const investmentHorizon = parseInt(this.form.investmentHorizon.replace(/,/g, "."));

      const targetHorizont = Math.min(investmentHorizon, 20);
      const targetSum = 12 * monthlyAmount * targetHorizont;

      // Log targetSum to check its value
      console.log("targetSum:", targetSum);

      const feeType = this.form.feeType;
      const feeMultiplier = this.getFeeMultiplier(); // Replace with the actual fee calculation based on the fee type

      // Log feeMultiplier to check its value
      console.log("feeMultiplier:", feeMultiplier);

      const discountPercentage = parseFloat(this.form.discount.replace(/,/g, ".")) / 100;

      let finalSum;
      if (discountPercentage === 0) {
        finalSum = (targetSum * feeMultiplier / 100).toFixed(2);
      } else {
        const discountedSum = targetSum - discountPercentage * targetSum;
        finalSum = (discountedSum * feeMultiplier / 100).toFixed(2); // Format to 2 decimal places
      }

      this.form.entryFee = parseFloat(finalSum) || 0; // If finalSum is NaN, it will be converted to 0.
      return finalSum;
    },
    isLocaleEn() {
      return typeof localStorage !== 'undefined' && localStorage.getItem('wem_locale') === 'en';
    },

  },
  watch: {
    targetAmount(newVal) {
      this.form.entryFee = parseFloat(newVal);
    }
  },

  methods: {
    // restrictToNumbers(event: Event) {
    //   const input = (event.target as HTMLInputElement).value;
    //   (event.target as HTMLInputElement).value = input.replace(/\D/g, "");
    // },
    restrictToNumbers(event: Event) {
      const input = (event.target as HTMLInputElement).value;
      // Allow only numbers and a dot for decimal input
      const filteredInput = input.replace(/[^\d.]/g, "");
      (event.target as HTMLInputElement).value = filteredInput;
    },

    getFeeMultiplier() {
      if (this.form.feeType === "one-time") {
        return this.strategy.one_off_entry_fee;
      } else (this.form.feeType === "regular");
      {
        return this.strategy.regular_entry_fee;
      }
    },

    getRiskProfiileName(riskProfileId: number) {
      if (!this.isLocaleEn) {
        if (riskProfileId === 2) {
          return "Konzervatívna";
        } else if (riskProfileId === 3) {
          return "Vyvážená";
        } else if (riskProfileId === 4) {
          return "Dynamická";
        } else if (riskProfileId === 5) {
          return "Komisionárska";
        }
      } else {
        if (riskProfileId === 2) {
          return "Conservative";
        } else if (riskProfileId === 3) {
          return "Balanced";
        } else if (riskProfileId === 4) {
          return "Dynamic";
        } else if (riskProfileId === 5) {
          return "Commission";
        }
      }
    },

    selectStrategy(event: any, strategy: InvestmentStrategy) {
      this.form.selected = event.target.checked;
      this.$store.commit(
        "onboarding/selectInvestmentStrategy",
        this.strategyData(strategy)
      );
    },

    updateSelectedStrategy(event: any, strategy: InvestmentStrategy) {
      this.v$.$validate();
      const dummy = this.targetAmount;

      this.$store.commit(
        "onboarding/updateSelectedStrategy",
        this.strategyData(strategy)
      );
    },

    strategyData(s: any): SelectedStrategy {
      const strategy: any = {
        investment_strategy_id: s.id,
        deposit_frequency: this.form.frequency,
        investment_monthly: parseFloat(this.form.amount.replace(",", ".")) || 0,
        min_investment: s.min_investment || 0,
        feeType: this.form.feeType,
        monthlyAmount: parseFloat(this.form.monthlyAmount.replace(/,/g, ".")) || 0,
        investmentHorizon: this.form.investmentHorizon,
        discount: this.form.discount,
        entryFee: this.form.entryFee,
        contract_type_id: s.contract_type_id,
      };
      return strategy;
    }
  },
  validations() {
    return {
      form: {
        frequency: { required },
        amount: {
          required,
          minValue: helpers.withMessage(
            this.$t("validations.minValuePositiveNumber", {
              number: this.strategy.min_investment || 0
            }),
            minValue(this.strategy.min_investment || 0)
          )
        },
        feeType: { required },
        monthlyAmount: { required },
        investmentHorizon: { required },
        discount: { required }

      }
    };
  }
})
export default class InvestmentStrategyCard extends Vue {
}
