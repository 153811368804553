import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full text-secondary" }
const _hoisted_2 = ["value", "type", "disabled", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "text-danger text-xs absolute mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`text-primary-300 relative text-${_ctx.textSize}`)
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("input", {
      value: _ctx.model,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateModel($event))),
      type: _ctx.type,
      disabled: _ctx.disabled,
      placeholder: _ctx.placeholder || '',
      class: "\n        w-full\n        block\n        bg-transparent\n        focus:outline-none\n        border-b-2 border-secondary\n      "
    }, null, 40, _hoisted_2),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.error.$message), 1))
      : _createCommentVNode("", true)
  ], 2))
}