import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "justify-between text-lg items-center" }
const _hoisted_2 = { class: "mt-2 text-xs text-secondary block" }
const _hoisted_3 = { class: "mt-8" }
const _hoisted_4 = { class: "items-center mt-3 text-right" }
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`border-2 border-${_ctx.customClass} p-5 flex justify-between flex-col`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.cardTitle), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.contractDate), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          href: _ctx.link,
          target: "_blank",
          class: _normalizeClass(`mt-1 uppercase text-${_ctx.customClass}`)
        }, _toDisplayString(_ctx.$t("open")), 11, _hoisted_5)
      ])
    ])
  ], 2))
}