import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-secondary mb-7 relative text-sm" }
const _hoisted_2 = { class: "flex items-start" }
const _hoisted_3 = ["id", "value"]
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 0,
  class: "text-danger text-xs absolute mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        id: _ctx.id,
        value: _ctx.model,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model', $event.target.checked))),
        type: "checkbox"
      }, null, 40, _hoisted_3),
      _createElementVNode("label", {
        for: _ctx.id,
        class: "mr-2"
      }, null, 8, _hoisted_4),
      _renderSlot(_ctx.$slots, "default")
    ]),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}