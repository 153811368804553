
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {};
  },
  props: {
    customClass: {
      type: String,
    },
    cardTitle: {
      type: String,
    },
    link: {
      type: String,
    },
    contractDate: {
      type: String,
    },
  },
})
export default class ContractCard extends Vue {}
