import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-723f8d23")
const _hoisted_1 = { class: "border-2 border-primary-300 p-5 my-4 md:my-0" }
const _hoisted_2 = { class: "flex justify-between text-lg items-center pb-2" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: "badge-grey",
  style: {"padding":"7px 10px 5px 10px","margin":"0 0 3px 0","font-size":"13px"}
}
const _hoisted_6 = {
  key: 1,
  class: "mb-2 text-sm"
}
const _hoisted_7 = { class: "text-secondary uppercase text-xxs mt-3" }
const _hoisted_8 = { class: "mt-5" }
const _hoisted_9 = {
  key: 0,
  class: "text-xxs font-sofia"
}
const _hoisted_10 = {
  key: 1,
  class: "font-sofia"
}
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "flex items-center mt-3" }
const _hoisted_13 = ["model", "id"]
const _hoisted_14 = ["for"]
const _hoisted_15 = { class: "ml-3 mt-1 uppercase" }
const _hoisted_16 = {
  key: 3,
  class: "mt-5 w-11/12"
}
const _hoisted_17 = {
  key: 6,
  style: {"margin-top":"20px"}
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormSelect = _resolveComponent("FormSelect")!
  const _component_FormInput = _resolveComponent("FormInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.cardTitle), 1),
      (typeof _ctx.localStorage !== 'undefined' && _ctx.localStorage.getItem('wem_locale') === 'sk')
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: _ctx.strategy.link,
            target: "_blank",
            class: "text-4xl font-light"
          }, "+", 8, _hoisted_3))
        : (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: _ctx.strategy.link_en,
            target: "_blank",
            class: "text-4xl font-light"
          }, "+", 8, _hoisted_4))
    ]),
    (_ctx.strategy.risk_profile_id !== 0 || _ctx.strategy.risk_profile_id !== 1)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getRiskProfiileName(_ctx.strategy.risk_profile_id)), 1))
      : _createCommentVNode("", true),
    (_ctx.cardSubtitle)
      ? (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(_ctx.cardSubtitle), 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.strategy.subtitle), 1),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.strategy.risk_profile_id !== 5)
        ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.$t("annual_profit")) + " *", 1))
        : _createCommentVNode("", true),
      (_ctx.strategy.performance && _ctx.strategy.risk_profile_id !== 5)
        ? (_openBlock(), _createElementBlock("h4", _hoisted_10, _toDisplayString(_ctx.strategy.performance) + " % p.a.", 1))
        : (!_ctx.isLocaleEn && _ctx.strategy.risk_profile_id !== 5)
          ? (_openBlock(), _createElementBlock("h4", _hoisted_11, "neuvedené"))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("input", {
          model: _ctx.form.selected,
          id: `strategy${_ctx.strategy.id}`,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectStrategy($event, _ctx.strategy))),
          type: "checkbox"
        }, null, 40, _hoisted_13),
        _createElementVNode("label", {
          for: `strategy${_ctx.strategy.id}`
        }, null, 8, _hoisted_14),
        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t("choose")), 1)
      ]),
      (_ctx.form.selected && _ctx.strategy.risk_profile_id !== 5)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            (_ctx.hasInvestmentFrequency && !_ctx.strategy.show_entry_fee)
              ? (_openBlock(), _createBlock(_component_FormSelect, {
                  key: 0,
                  onValueChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateSelectedStrategy($event, _ctx.strategy))),
                  options: _ctx.investmentFrequency,
                  textSize: "xs",
                  class: "mb-4",
                  label: _ctx.$t('frequency'),
                  type: "text",
                  model: _ctx.form.frequency,
                  "onUpdate:model": _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.frequency = $event)),
                  valueKey: "value",
                  titleKey: "name",
                  field: _ctx.form.frequency,
                  hasError: _ctx.v$.form.frequency.$error,
                  error: _ctx.v$.form.frequency.$errors[0]
                }, null, 8, ["options", "label", "model", "field", "hasError", "error"]))
              : _createCommentVNode("", true),
            (!_ctx.strategy.show_entry_fee && _ctx.strategy.risk_profile_id !== 5)
              ? (_openBlock(), _createBlock(_component_FormInput, {
                  key: 1,
                  onValueChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateSelectedStrategy($event, _ctx.strategy))),
                  label: _ctx.amountLabel,
                  type: "text",
                  textSize: "xs",
                  model: _ctx.form.amount,
                  "onUpdate:model": _cache[4] || (_cache[4] = ($event: any) => (_ctx.form.amount = $event)),
                  field: _ctx.form.amount,
                  hasError: _ctx.v$.form.amount.$error,
                  error: _ctx.v$.form.amount.$errors[0]
                }, null, 8, ["label", "model", "field", "hasError", "error"]))
              : _createCommentVNode("", true),
            (_ctx.strategy.show_entry_fee)
              ? (_openBlock(), _createBlock(_component_FormInput, {
                  key: 2,
                  style: {"margin-top":"14px"},
                  onValueChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updateSelectedStrategy($event, _ctx.strategy))),
                  label: _ctx.$t('mountly_deposit'),
                  type: "text",
                  textSize: "xs",
                  model: _ctx.form.monthlyAmount,
                  "onUpdate:model": _cache[6] || (_cache[6] = ($event: any) => (_ctx.form.monthlyAmount = $event)),
                  field: _ctx.form.monthlyAmount,
                  hasError: _ctx.v$.form.monthlyAmount.$error,
                  error: _ctx.v$.form.monthlyAmount.$errors[0]
                }, null, 8, ["label", "model", "field", "hasError", "error"]))
              : _createCommentVNode("", true),
            (_ctx.strategy.show_entry_fee)
              ? (_openBlock(), _createBlock(_component_FormInput, {
                  key: 3,
                  style: {"margin-top":"14px"},
                  onValueChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateSelectedStrategy($event, _ctx.strategy))),
                  label: _ctx.$t('investment_horizont'),
                  type: "text",
                  textSize: "xs",
                  model: _ctx.form.investmentHorizon,
                  "onUpdate:model": _cache[8] || (_cache[8] = ($event: any) => (_ctx.form.investmentHorizon = $event)),
                  field: _ctx.form.investmentHorizon,
                  hasError: _ctx.v$.form.investmentHorizon.$error,
                  error: _ctx.v$.form.investmentHorizon.$errors[0]
                }, null, 8, ["label", "model", "field", "hasError", "error"]))
              : _createCommentVNode("", true),
            (_ctx.strategy.show_entry_fee)
              ? (_openBlock(), _createBlock(_component_FormSelect, {
                  key: 4,
                  onValueChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateSelectedStrategy($event, _ctx.strategy))),
                  style: {"margin-top":"14px"},
                  options: _ctx.feeType,
                  textSize: "xs",
                  class: "mb-4",
                  label: _ctx.$t('fee_type'),
                  type: "text",
                  model: _ctx.form.feeType,
                  "onUpdate:model": _cache[10] || (_cache[10] = ($event: any) => (_ctx.form.feeType = $event)),
                  valueKey: "value",
                  titleKey: "name",
                  field: _ctx.form.feeType,
                  hasError: _ctx.v$.form.feeType.$error,
                  error: _ctx.v$.form.feeType.$errors[0]
                }, null, 8, ["options", "label", "model", "field", "hasError", "error"]))
              : _createCommentVNode("", true),
            (_ctx.strategy.show_entry_fee)
              ? (_openBlock(), _createBlock(_component_FormInput, {
                  key: 5,
                  onInput: _ctx.restrictToNumbers,
                  onValueChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updateSelectedStrategy($event, _ctx.strategy))),
                  style: {"margin-top":"14px"},
                  label: _ctx.$t('discount'),
                  type: "text",
                  textSize: "xs",
                  model: _ctx.form.discount,
                  "onUpdate:model": _cache[12] || (_cache[12] = ($event: any) => (_ctx.form.discount = $event)),
                  field: _ctx.form.discount,
                  hasError: _ctx.v$.form.discount.$error,
                  error: _ctx.v$.form.discount.$errors[0]
                }, null, 8, ["onInput", "label", "model", "field", "hasError", "error"]))
              : _createCommentVNode("", true),
            (_ctx.strategy.show_entry_fee && _ctx.targetAmount >= 1 )
              ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.$t("entry_fee_text")) + " " + _toDisplayString(_ctx.targetAmount) + " EUR", 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}