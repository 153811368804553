
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    model: {
      required: true,
    },
    label: {
      required: true,
      type: String,
    },
    textSize: {
      type: String,
      default: "sm",
    },
    options: {
      required: true,
      type: Array,
    },
    titleKey: {
      required: true,
      type: String,
    },
    valueKey: {
      required: true,
      type: String,
    },
    hasError: {
      required: true,
      type: Boolean,
    },
    error: {
      required: true,
    },
  },
  methods: {
    updateModel(event: any) {
      this.$emit("update:model", event.target.value);
      this.$emit("valueChange", event.target.value);
    },
  },
})
export default class FormSelect extends Vue {}
