
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    model: {
      required: true,
    },
    placeholder: {
      required: false,
    },
    textSize: {
      type: String,
      default: "sm",
    },
    label: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hasError: {
      required: false,
      type: Boolean,
    },
    error: {
      required: false,
    },
  },
  methods: {
    updateModel(event: any) {
      this.$emit("updateModel", event.target.value);
      this.$emit("update:model", event.target.value);
      this.$emit("valueChange", event.target.value);
    },
  },
})
export default class FormInput extends Vue {}
